<template>
  <i-modal
    :title="modalTitle"
    :value="value"
    :closed="true"
    :load="loading"
    :max-width="'1200px'"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row v-if="notification != false">
      <v-col cols="9">
        <v-row>
          <v-col cols="3">
            <v-switch v-model="have_role" :label="$t('filter_role')"></v-switch>
          </v-col>
          <v-col cols="9" v-if="have_role">
            <v-autocomplete
              autocomplete="off"
              outlined
              v-model="selectedRol"
              :items="roles"
              class="secondary--text role"
              item-text="name"
              item-value="pk"
              :label="$tc('roles', 2)"
            />
          </v-col>

          <v-col cols="12">
            <ValidationProvider
              vid="user"
              :name="$tc('user', 2)"
              rules="required"
              v-slot="{ errors }"
            >
              <v-autocomplete
                autocomplete="off"
                outlined
                v-model="notification.users"
                :error-messages="errors[0]"
                :items="users"
                chips
                deletable-chips
                :item-text="getName"
                item-value="id"
                :label="$tc('user', 2)"
                multiple
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-skeleton-loader v-if="loading" type="button" tile />
            <v-switch
              v-show="!loading"
              v-model="notification.email"
              :label="$tc('email', 1)"
              color="primary"
            />
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader v-if="loading" type="button" tile />
            <v-switch
              v-show="!loading"
              v-model="notification.push"
              :label="$tc('push', 1)"
              color="primary"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
        <span class="title grey--text font-weight-black">
          {{ $tc('color', 1) }}
        </span>
        <v-color-picker
          elevation="1"
          mode="hexa"
          canvas-height="120"
          dot-size="18"
          v-model="notification.background"
        />
      </v-col>
    </v-row>
  </i-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      loading: false,
      edit: false,
      have_role: false,
      notification: {
        email: false,
        push: false,
        event: '',
        background: '',
        users: []
      },
      selectedRol: [],
      roles: [],
      users: []
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData'
    }),
    modalTitle() {
      return this.$t(this.edit ? 'editing' : 'add', {
        value: this.toEdit !== null ? this.$tc(this.toEdit.model, 1) : ''
      })
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.notification = this.edit
            ? { ...this.toEdit }
            : {
                email: false,
                push: false,
                event: '',
                background: '',
                users: []
              }
        }
      }
    },
    selectedRol: {
      handler(val) {
        if (val) {
          this.getUsers(val)
        }
      }
    }
  },
  methods: {
    /**
     * submit
     * Envía los datos del estado actual de la notificación para crear o editar
     * un estado.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async submit() {
      if (this.loading) return

      this.loading = true
      try {
        const notification =
          this.toEdit.pk === ''
            ? this.$api.notifications.create({ form: this.notification })
            : this.$api.notifications.edit({
                pk: this.notification.pk,
                form: this.notification
              })

        await notification
        this.$emit('done')
        this.$emit('input', false)
        this.$toast.success(
          `${this.$tc('notifications', 1)} ${this.$tc(
            this.edit ? 'edited' : 'created',
            1
          )}`
        )
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    /**
     * getName
     * Devuelve el nombre completo del usuario y su rol.
     *
     * @param {Object} item - El objeto del usuario.
     * @returns {string} El nombre completo del usuario.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    getName(item) {
      return `${item.groups?.[0]?.name ? item.groups[0].name + ': ' : ''}${
        item.first_name
      } ${item.last_name}`
    },
    /**
     * getRoles
     * Obtiene la lista de roles y los asigna al estado local.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getRoles() {
      const { data } = await this.$api.user.group({})
      this.roles = data
    },
    /**
     * getUsers
     * Obtiene la lista de usuarios y según el rol si hay uno seleccionado.
     *
     * @param {string} rol - El rol seleccionado.
     *
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getUsers(rol) {
      const params = new URLSearchParams({ company: this.company.pk })
      if (rol) params.append('role', rol)

      const { data } = await this.$api.user.list({ opt: { params } })
      this.users = data.results
    }
  },
  mounted() {
    this.getRoles()
    this.getUsers()
  }
}
</script>
